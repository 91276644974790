import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileManagerPipe'
})
export class FileManagerPipe implements PipeTransform{

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      if (it.type) {
        return it.originalName.toLowerCase().includes(searchText);
      } else {
        return it.name.toLowerCase().includes(searchText);
      }
    });
  }

}
