import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'listPipe'
})
export class ListPipePipe implements PipeTransform {

	transform(items: any[], nameUserName: string, zipCode: string, languages: string, stateLicense: string): any[] {
		if (!items) {
			return [];
		}

		if (nameUserName) {
			nameUserName = nameUserName.toLowerCase();
		}

		return items.filter(it => {
			if (nameUserName && !`${it.firstName} ${it.lastName}`.toLowerCase().includes(nameUserName)) {
				return false;
			}
			if ((zipCode && zipCode[0]) && (it.zipCode === null)) {
				return false;
			}
			if ((zipCode && zipCode[0]) && (it.zipCode !== null)) {
				const l = zipCode.length;
				let cont = 0;
				for (let i = 0; i < l; i++) {
					if (+it.zipCode === +zipCode[i]) {
						cont++
					}
				}
				if (cont === 0) {
					return false;
				}
			}

			if ((stateLicense && stateLicense[0]) && (it.stateLicense === null)) {
				return false;
			}
			if ((stateLicense && stateLicense[0]) && (it.stateLicense !== null)) {
				const l = stateLicense.length;
				let cont = 0;
				for (let i = 0; i < l; i++) {
					if (it.stateLicense === stateLicense[i]) {
						cont++
					}
				}
				if (cont === 0) {
					return false;
				}
			}

			if ((languages && languages[0]) && (!it.languages || !it.languages[0])) {
				return false;
			}
			if ((languages && languages[0]) && (it.languages && it.languages[0])) {
				// !it.languages.join(', ').toLowerCase().includes(languages)
				const l = languages.length;
				let cont = 0;
				const lL = it.languages.length;
				for (let i = 0; i < l; i++) {
					for (let j = 0; j < lL; j++) {
						if (languages[i] === it.languages[j]) {
							cont++
						}
					}
				}
				if (cont === 0) {
					return false;
				}
			}
			return true;
		});
	}

}
