import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $( document ).ready(function() {
      const animation_elements = $('.animation-element');
      const window_ = $(window);
      function checkPosition() {
        const window_height = window_.height() || 0;
        const window_top_position = window_.scrollTop() || 0;
        const window_bottom_position = (window_top_position + window_height);

        $.each(animation_elements, function() {
          const element = $(this);
          const element_height = element.outerHeight() || 0;
          const elOffset = element.offset();
          const element_top_position = elOffset ? elOffset.top + 150 : 0;

          const element_bottom_position = (element_top_position + element_height);
          if ((element_bottom_position >= window_top_position) &&
              (element_top_position <= window_bottom_position)) {
            element.addClass('in-view');
          } else {
            element.removeClass('in-view');
          }
        });
      }
      window_.on('scroll', checkPosition);
    });
  }

}
