import { Component, OnInit } from '@angular/core';
import { markFormGroupTouched } from '../../constants/app.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import { AuthService } from '../../services/auth.service';
import { hexToBase64URL } from '../../constants/hexBase64';
import * as sha256 from 'sha256';
import { MustMatch } from '../../constants/app.config';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
    public form: FormGroup;
    public isReady = false;
    public isToken = false;
    private token: any;

    constructor(private fb: FormBuilder,
                private http: HttpService,
                private authSvc: AuthService,
                private router: Router) {
        const href = window.location.href;
        if (href.indexOf('forget-password?token') > -1) {
            const url = new URL(href);
            this.isToken = true;
            this.token = url.searchParams.get('token');
        }
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            email: [{
                value: '',
                disabled: false,
            }, !this.isToken ? [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')] : [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            password: [{
                value: '',
                disabled: false,
            }, this.isToken ? [Validators.required] : []],
            confirmPassword: [{
                value: '',
                disabled: false,
            }, this.isToken ? [Validators.required] : []],
        }, {
            validator: MustMatch('password', 'confirmPassword'),
        });
    }

    onSubmit() {
        markFormGroupTouched(this.form);
        if (this.form.valid) {
            const value = this.form.getRawValue();
            if (!this.isToken) {
                this.http.post('/forget-password', { email: value.email }).then((res: any) => {
                    if (res) {
                        this.isReady = true;
                    }
                });
            } else {
                value.password = hexToBase64URL(sha256(value.password));
                this.http.post('/forget-password/change', { password: value.password }, {}, { Authorization: this.token })
                    .then((res: any) => {
                        if (res) {
                            this.router.navigate(['sign-in']).then();
                        }
                    });
            }
        }
    }

}
