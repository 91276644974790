import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { appConfig } from '../constants/app.config';
import { NotificationService } from "./notification.service";


@Injectable()
export class HttpService {

    constructor(
        private router: Router,
        private http: HttpClient,
        private notifySvc: NotificationService,
    ) {
    }

    static addOptions(params?: HttpParams, headers = {}) {
        const options: any = {};
        if (params) {
            options.params = params;
        }
        if (!headers['Content-Type']) {
            options.headers = {
                'Content-Type': 'application/json',
                ...headers
            };
        } else {
            options.headers = {
                ...headers
            };
        }
        const token = localStorage.getItem('token');
        if (token) {
            options.headers.Authorization = `Bearer ${token}`;
        }
        options.observe = 'response';
        return options;
    }

    static handleError(error: any) {
        if (error instanceof HttpErrorResponse) {
            return ErrorObservable.create(error);
        }
    }

    get(url: string, params: any = {}) {
        return this.http.get<any>(appConfig.apiUrl + url, HttpService.addOptions(this.toHttpParams(params)))
            .catch((error: any) => {
                this.notifySvc.setMessage({
                    message: error.error.error ? error.error.error : error.error.message,
                    type: 'warning'
                });
                return HttpService.handleError(error);
            }).toPromise()
    }

    post(url: string, body: any = {}, params: any = {}, headers = {}) {
        return this.http.post<any>(appConfig.apiUrl + url, body, HttpService.addOptions(this.toHttpParams(params), headers))
            .catch((error: any) => {
                error.error
                this.notifySvc.setMessage({
                    message: error.error.error ? error.error.error : error.error.message,
                    type: 'warning'
                });
                return HttpService.handleError(error);
            }).toPromise().then((res) => {
                this.notifySvc.setMessage({
                    message: 'Your actions succeeded',
                    type: 'success'
                });
                return res;
            });
    }

    put(url: string, body: any = {}, params: any = {}) {
        return this.http.put<any>(appConfig.apiUrl + url, body, HttpService.addOptions(this.toHttpParams(params)))
            .catch((error: any) => {
                this.notifySvc.setMessage({
                    message: error.error.error ? error.error.error : error.error.message,
                    type: 'warning'
                });
                return HttpService.handleError(error);
            }).toPromise().then((res) => {
                if (url.indexOf('read-many') <= 0) {
                    this.notifySvc.setMessage({
                        message: 'Your actions succeeded',
                        type: 'success'
                    });
                }

                return res;
            });
    }

    patch(url: string, body: any = {}, params: any = {}) {
        return this.http.patch<any>(appConfig.apiUrl + url, body, HttpService.addOptions(this.toHttpParams(params)))
            .catch((error: any) => {
                this.notifySvc.setMessage({
                    message: error.error.error ? error.error.error : error.error.message,
                    type: 'warning'
                });
                return HttpService.handleError(error);
            }).toPromise().then((res) => {
                this.notifySvc.setMessage({
                    message: 'Your actions succeeded',
                    type: 'success'
                });
                return res;
            });
    }

    delete(url: string, params: any = {}) {
        return this.http.delete<any>(appConfig.apiUrl + url, HttpService.addOptions(this.toHttpParams(params)))
            .catch((error: any) => {
                this.notifySvc.setMessage({
                    message: error.error.error ? error.error.error : error.error.message,
                    type: 'warning'
                });
                return HttpService.handleError(error);
            }).toPromise().then((res) => {
                this.notifySvc.setMessage({
                    message: 'Your actions succeeded',
                    type: 'success'
                });
                return res;
            });
    }

    fetch(url: string, option?: any, parameters?: any) {
        return fetch(appConfig.apiUrl + url, option);
    }

    download(url: string): any {
        return this.http.get(url, {
            responseType: 'blob'
        }).catch((error: any) => {
            return HttpService.handleError(error);
        }).toPromise();
    }

    private toHttpParams(params: any) {
        params = this.checkParams(params);

        return Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams());
    }

    private checkParams(obj) {
        return JSON.parse(JSON.stringify(obj,
            (k, v) => {
                if (v === null) {
                    return undefined;
                }
                return v;
            }
        ));
    }

    /*success(message: string) {
        this.alertService.success(message);
    }

    error(message: string) {
        this.loadingSvc.setLoadingState(false);
        this.alertService.error(message);
    }*/
}
