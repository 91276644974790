import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { HttpService } from './services/http.service';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { SocketService } from './services/socket.service';
import { HeaderService } from './services/header.service';
import { HomeComponent } from './components/home/home.component';
import { NotificationService } from "./services/notification.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        SignUpComponent,
        EmailVerificationComponent,
        /*directives*/
        ForgetPasswordComponent,
        HomeComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        NgbModule,
    ],
    providers: [
        AuthService,
        AuthGuard,
        HttpClient,
        HttpService,
        SocketService,
        HeaderService,
        NotificationService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
