import { Component } from '@angular/core';
import { NotificationService } from "./services/notification.service";
import { LoadingService } from "./services/loading.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public title = 'taxul-FED';
    public loading$ = this.loader.loading$;
    public notifyMessage: any = [];
    public notifyClasses = {
        warning: {
            class: 'mdi mdi-block-helper me-2'
        },
        success: {
            class: 'mdi mdi-check-all me-2'
        },
    }
    private interval: any;

    constructor(private notifySvc: NotificationService,
                public loader: LoadingService) {
        notifySvc.getMessage().subscribe((message: any) => {
            this.notifyMessage.push(message);
            if (!this.interval) {
                this.interval = setInterval(() => {
                    if (this.notifyMessage.length > 0) {
                        this.notifyMessage.pop();
                        if (this.notifyMessage.length === 0) {
                            clearInterval(this.interval);
                            this.interval = null;
                        }
                    }
                }, 5000);
            }
        })
    }

    close(alert: any) {
        this.notifyMessage.splice(this.notifyMessage.indexOf(alert), 1);
    }

}
