import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'orderByDatePipe',
    pure: false
})
export class OrderByPipe implements PipeTransform{

    transform(array: Array<any>, path?: any, forMessage:boolean = true): Array<any> {
        if(!array || array === undefined || array.length === 0) return null;
        if (forMessage) {
            array.sort((a: any, b: any) => {
                return +new Date(a.timeStamp) - +new Date(b.timeStamp)
            });
        } else {
            array.sort((a: any, b: any) => {
                if (b[path][0] && a[path][0]) {
                    return +new Date(b[path][b[path].length - 1].timeStamp) - +new Date(a[path][a[path].length - 1].timeStamp)
                } else {
                    return 0
                }
            });
        }
        return array;
    }

}
