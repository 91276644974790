import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io } from 'socket.io-client';
import {appConfig} from "../constants/app.config";


@Injectable()
export class SocketService {
    private socket: any;
    private audio = new Audio('./assets/audio/message.wav');
    public onMassage = new BehaviorSubject(null);
    public activeUsers = new BehaviorSubject(null);

    constructor() {
    }

    connect() {
        this.socket = io(appConfig.socketURL, {
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            }
        });
        this.socket.on('connect_error', () => {
            this.socket.io.opts.transports = ['polling', 'websocket'];
        });
        this.socket.on('connect', () => {
            this.socket.emit('hi', {});
        });
        this.socket.on('message', (data) => {
            if (localStorage.getItem('_id') !== data.sender) {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.audio.play().then();
            }
            this.onMassage.next(data);
        });
        this.socket.on('users', (data) => {
            this.activeUsers.next(data);
        });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    sendMass(message: any) {
        this.socket.emit('message', message);
    }

    updateMass(message: any) {
        this.socket.emit('editMessage', message);
    }

    readOneMessage(id: any) {
        this.socket.emit('readOneMessage', {_id: id});
    }

    getActiveUsers() {
        return this.activeUsers;
    }
    getMassage() {
        return this.onMassage;
    }
}
