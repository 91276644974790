import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort'
})
export class SortPipe implements PipeTransform {

	transform(array: any, field: string, asc: boolean): any[] {
		if (!Array.isArray(array)) {
			return;
		}
		array.sort((a: any, b: any) => {
			if (+a[field] < +b[field]) {
				return asc ? -1 : 1;
			} else if (+a[field] > +b[field]) {
				return asc ? 1 : -1;
			} else {
				return 0;
			}
		});
		return array;
	}

}
