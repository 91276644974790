import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
// import { appConfig } from '../constants/app.config';


@Injectable()

export class AuthGuard implements CanActivate {
  private isFirst = true;
  private oldState: string;

  constructor(private authSvc: AuthService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.isFirst) {
      this.isFirst = false;
      this.oldState = state.url;
    }
    if (this.authSvc.isLogged && this.oldState) {
      this.router.navigate(this.oldState === '/' ? [/*appConfig.defaultHomePage*/] : [this.oldState])
        .then();
      this.oldState = '';
    }
    return this.authSvc.isLogged;
  }
}
