import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import hexToBase64, { hexToBase64URL } from '../constants/hexBase64';
import * as sha256 from 'sha256';
import { HttpService } from './http.service';
import { HttpResponse } from '@angular/common/http';
import { appConfig } from '../constants/app.config';
import { SocketService } from './socket.service';
import { error } from 'protractor';

@Injectable()
export class AuthService {
    public isLogged: boolean;
    public token = localStorage.getItem('token');

    constructor(private router: Router,
                private route: ActivatedRoute,
                private http: HttpService,
                private socketSvc: SocketService) {
        const href = window.location.href;
        if (href.indexOf('forget-password?token') < 0) {
            this.token = localStorage.getItem('token');
            if (this.token) {
                this.authorize();
            } else {
                this.logout();
            }
        }
    }

    logout() {
        this.isLogged = false;
        localStorage.clear();
        this.socketSvc.disconnect();
        this.router.navigate(['/sign-in']).then();
    }

    login(credentials: any) {
       return  this.http.post('/auth', credentials).catch((error: any) => {
           return error
        }).then((res: HttpResponse<any>) => {
            if (res) {
                this.saveLocalData(res.body);
                this.goMainPage();
            }
        });
    }

    verify(token: string) {
        return new Promise(resolve => {
            this.http.get(`/user/verify/${token}`).then((res: HttpResponse<any>) => {
                if (res) {
                    this.saveLocalData(res.body);
                    resolve(res.body);
                }
            });
        })
    }

    authorize() {
        this.http.post('/authorize', {token: this.token}).then((res: HttpResponse<any>) => {
            this.saveLocalData(res.body);
            this.goMainPage();
        }).catch(() => {
            this.logout();
        });
    }

    goMainPage() {
        this.http.get('/user/me').then((res: any) => {
            if (res) {
                localStorage.setItem('me', JSON.stringify(res.body))
                if (this.router.url.indexOf('sign-in') > 0 || this.router.url === '/' || window.location.hash) {
                    this.isLogged = true;
                    this.router.navigate([appConfig.defaultHomePage], { relativeTo: this.route }).then();
                }
                this.socketSvc.connect();
            }
        });
    }

    saveLocalData(data: any) {
        localStorage.setItem('token', data.access_token);
    }
}
