import { AfterViewInit, Component, OnInit } from '@angular/core';
import { gender, MustMatch, role, states } from '../../constants/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import { markFormGroupTouched } from '../../constants/app.constants';
import { hexToBase64URL } from '../../constants/hexBase64';
import sha256 from 'sha256';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, AfterViewInit {
    public form: FormGroup;
    public genderDrop = false;
    public roleDrop = false;
    public stateDrop = false;
    public stateDropLicence = false;
    public genderValues = gender;
    public roleValues = role;
    public stateEnum = states;
    public ObjectKeys = Object.keys;
    public isFinish = false;
    public year = new Date().getFullYear();

    constructor(private fb: FormBuilder,
                private http: HttpService) {
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit() {
        this.initForm();
    }

    ngAfterViewInit() {
    }

    initForm() {
        this.form = this.fb.group({
            firstName: [{
                value: '',
                disabled: false,
            }, Validators.required],
            lastName: [{
                value: '',
                disabled: false,
            }, Validators.required],
            email: [{
                value: '',
                disabled: false,
            }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            password: [{
                value: '',
                disabled: false,
            }, Validators.required],
            confirmPassword: [{
                value: '',
                disabled: false,
            }, Validators.required],
            gender: [{
                value: '',
                disabled: false,
            }, Validators.required],
            birthDate: [{
                value: '',
                disabled: false,
            }, Validators.required],
            socialNumber: [{
                value: '',
                disabled: false,
            }, Validators.required],
            phoneNumber: [{
                value: null,
                disabled: false,
            }, Validators.required],
            role: [{
                value: this.roleValues[0],
                disabled: false,
            }, Validators.required],
            stateLicense: [{
                value: '',
                disabled: false}],
            state: [{
                value: '',
                disabled: false}],
            federalID: [{
                value: '',
                disabled: false}],
            stateID: [{
                value: '',
                disabled: false}],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    setSelectValue(value: any, control: any) {
        this.form.get(control).setValue(value);
        if (control === 'role') {
            if (value === this.roleValues[1]) {
                this.form.get('birthDate').clearValidators();
                this.form.get('gender').clearValidators();
                this.form.get('socialNumber').clearValidators();
                this.form.get('phoneNumber').clearValidators();
                this.form.get('birthDate').updateValueAndValidity();
                this.form.get('gender').updateValueAndValidity();
                this.form.get('socialNumber').updateValueAndValidity();
                this.form.get('phoneNumber').updateValueAndValidity();


                this.form.get('state').setValidators([Validators.required]);
                this.form.get('stateLicense').setValidators([Validators.required]);
                this.form.get('federalID').setValidators([Validators.required]);
                this.form.get('stateID').setValidators([Validators.required]);
                this.form.get('state').updateValueAndValidity();
                this.form.get('federalID').updateValueAndValidity();
                this.form.get('stateID').updateValueAndValidity();
            } else {
                this.form.get('state').clearValidators();
                this.form.get('stateLicense').clearValidators();
                this.form.get('federalID').clearValidators();
                this.form.get('stateID').clearValidators();
                this.form.get('state').updateValueAndValidity();
                this.form.get('federalID').updateValueAndValidity();
                this.form.get('stateID').updateValueAndValidity();

                this.form.get('birthDate').setValidators([Validators.required]);
                this.form.get('gender').setValidators([Validators.required]);
                this.form.get('socialNumber').setValidators([Validators.required]);
                this.form.get('phoneNumber').setValidators([Validators.required]);
                this.form.get('birthDate').updateValueAndValidity();
                this.form.get('gender').updateValueAndValidity();
                this.form.get('socialNumber').updateValueAndValidity();
                this.form.get('phoneNumber').updateValueAndValidity();
            }
        }
    }

    fillLate(event: any, control: any) {
        if (event.target.checked) {
            this.form.get(control).disable();
            this.form.get(control).clearValidators();
            this.form.get(control).setValue('');
        } else {
            this.form.get(control).enable();
            this.form.get(control).setValidators([Validators.required]);
            this.form.get(control).updateValueAndValidity();
        }
    }

    onSubmit() {
        markFormGroupTouched(this.form);
        if (this.form.valid) {
            const value = this.form.getRawValue();
            value.role = this.roleValues.indexOf(value.role);
            value.gender = this.genderValues.indexOf(value.gender) > -1 ? this.genderValues.indexOf(value.gender) : null;
            value.password = hexToBase64URL(sha256(value.password));
            this.http.post('/user', value).then((res: any) => {
                if (res) {
                    this.isFinish = true;
                }
            });
        }
    }

    isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105)
        );
    };

    isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) ||
            (key === 8 || key === 9 || key === 13 || key === 46) ||
            (key > 36 && key < 41) ||
            (
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    enforceFormat = (event) => {
        if(!this.isNumericInput(event) && !this.isModifierKey(event)){
            event.preventDefault();
        }
    };

    formatToPhone = (event) => {
        if(this.isModifierKey(event)) {return;}
        const target = event.target;
        const input = event.target.value.replace(/\D/g,'').substring(0,10);
        const zip = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);

        if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
        else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
        else if(input.length > 0){target.value = `(${zip}`;}
    };

}
