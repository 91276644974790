import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable()
export class HeaderService {
    public logChange = new Subject();
    public totalUnreadMessage = new BehaviorSubject(null);
    public role = new BehaviorSubject(null);

    constructor() {

    }

    getLogoChange() {
        return this.logChange;
    }

    setLogoChange(logo: string) {
        this.logChange.next(logo);
    }

    getTotalUnread() {
        return this.totalUnreadMessage;
    }

    setTotalUnread(num: number) {
        this.totalUnreadMessage.next(num);
    }


    getRole() {
        return this.role;
    }

    setRole(role: any) {
        this.role.next(role);
    }


}
