import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
  
  @Directive({
    selector: '[appClickOutside]'
  })
  export class ClickOutsideDirective {
    @Input() outsideActivate = true;
    @Input() parameter: any;
  
    @Output() clickOutside: EventEmitter<any> = new EventEmitter();

    constructor(
        private elementRef: ElementRef
    ) {}
  
    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
      const targetElement = event.target as HTMLElement;
      if (this.outsideActivate && targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
        this.clickOutside.emit(this.parameter);
      }
    }
  }
