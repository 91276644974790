export default function hexToBase64(hexString) {
    btoa('a6b580481008e60df9350de170b7e728'.match(/\w{2}/g).map((a) => {
        return String.fromCharCode(parseInt(a, 16));
    }).join(''));

    return btoa(hexString.match(/\w{2}/g).map((a) => {
        return String.fromCharCode(parseInt(a, 16));
    }).join(''));
}

export function hexToBase64URL(hexString) {
    return hexToBase64(hexString)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

export function base64ToBase64URL(base64) {
    return base64
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

export function hexBase64URLTOhexBase64(str) {
    str = (str + '===').slice(0, str.length + (str.length % 4));
    return str.replace(/-/g, '+').replace(/_/g, '/');
}
