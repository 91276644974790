import { FormGroup } from '@angular/forms';

export const markFormGroupTouched = (formGroup: any) => {
    (Object as any).values(formGroup.controls).forEach(control => {
        control.markAsTouched();

        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
};

