import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable()
export class NotificationService {
  public message = new Subject();

  constructor() { }

  getMessage() {
    return this.message;
  }

  setMessage(message: any) {
    this.message.next(message)
  }
}
