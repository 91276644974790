import { Component, OnDestroy, OnInit } from '@angular/core';
import { markFormGroupTouched } from '../../constants/app.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import { AuthService } from '../../services/auth.service';
import { hexToBase64URL } from '../../constants/hexBase64';
import * as sha256 from 'sha256';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public isFinish = false;
    public lastName: string;
    public firstName: string;
    public incorrectPass = false;
    public invalidMassage = false;
    private notSub: any;

    constructor(private fb: FormBuilder,
                private http: HttpService,
                private notifySvc: NotificationService,
                private authSvc: AuthService) {
        this.notSub = notifySvc.getMessage().subscribe((message: any) => {
            this.invalidMassage = (message.message === 'Unauthorized')
        });
        const href = window.location.href;
        if (href.indexOf('sign-in?token') > -1) {
            const url = new URL(href);
            const token = url.searchParams.get('token');
            this.authSvc.verify(token).then((body: any) => {
                this.isFinish = true;
                this.lastName = body.lastName;
                this.firstName = body.firstName;
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnDestroy() {
        this.notSub.unsubscribe();
    }

    initForm() {
        this.form = this.fb.group({
            email: [{
                value: '',
                disabled: false,
            }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
            password: [{
                value: '',
                disabled: false,
            }, Validators.required],
        });
    }

    goMainPage() {
        this.authSvc.goMainPage();
    }

    onSubmit() {
        this.incorrectPass = false;
        markFormGroupTouched(this.form);
        if (this.form.valid) {
            const value = this.form.getRawValue();
            value.password = hexToBase64URL(sha256(value.password));
            this.authSvc.login(value).catch((error) => {
                if (error) {
                    this.incorrectPass = true;
                }
            })
        }
    }

}
