import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClickOutsideDirective } from '../directives/click.outside.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FilterPipe } from '../pipes/filter.pipe';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListPipePipe } from '../pipes/list-pipe.pipe';
import { TagInputModule } from 'ngx-chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { FileManagerPipe } from '../pipes/file-manager.pipe';
import { CheckStatusPipe } from "../pipes/check-status.pipe";
import { SortPipe } from "../pipes/sort.pipe";


const ngWizardConfig: NgWizardConfig = {
    selected: 0,
    theme: THEME.circles,
    toolbarSettings: {
        toolbarExtraButtons: [
            { text: 'Save', class: 'btn btn-success', event: () => {
                    (document.getElementById('taxPayerProfileSubmit') as HTMLButtonElement).click();
                } }
        ],
    },
    anchorSettings: {
        enableAllAnchors: true,
    }
};


@NgModule({
    imports: [
        NgWizardModule.forRoot(ngWizardConfig),
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        ImageCropperModule,
        TagInputModule,
        MatProgressBarModule,
        DragAndDropModule
    ],
    declarations: [
        ClickOutsideDirective,
        OrderByPipe,
        FilterPipe,
        ListPipePipe,
        FileManagerPipe,
        SortPipe,
        CheckStatusPipe
    ],
    providers: [],
    entryComponents: [],
    exports: [
        NgWizardModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        ClickOutsideDirective,
        ImageCropperModule,
        OrderByPipe,
        FilterPipe,
        ListPipePipe,
        FileManagerPipe,
        SortPipe,
        CheckStatusPipe,
        TagInputModule,
        MatProgressBarModule,
        DragAndDropModule
    ],
})
export class SharedModule {
}
