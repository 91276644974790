import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'checkStatus'
})
export class CheckStatusPipe implements PipeTransform {

	transform(items: any[], checkStatus: number): any[] {
		if (!items) {
			return [];
		}
		
		return items.filter(it => {
			return it.status === checkStatus;
		});
	}

}
